const Footer = () => {
  return (
    <div
      style={{
        color: "gray",
        marginTop: "10px",
        borderTop: "1px solid #555",
        textAlign: "center",
      }}
    >
      Happy Managment
    </div>
  );
};
export default Footer;
